.viewport {
	position: fixed;
	z-index: 9999;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0;
	padding: 0;
	width: 100%;
	display: flex;
	justify-content: center;
}

.toast {
	background: var(--token-color-surface-warning);
	border-radius: var(--hdsplus-spacing-01);
	border: 1px solid var(--token-color-border-warning);

	/* Spacing */
	padding: var(--hdsplus-spacing-05);
	margin: var(--hdsplus-spacing-05);

	/* Layout */
	display: grid;
	column-gap: var(--hdsplus-spacing-05);
	row-gap: var(--hdsplus-spacing-03);
	grid-template-columns: max-content 1fr max-content;
	grid-template-areas:
		'icon title close'
		'icon description close'
		'icon cta close';

	@media (--medium-up) {
		max-width: 60ch;
		left: auto;
		right: auto;
	}
}

.icon {
	grid-area: icon;
}

.title {
	grid-area: title;
	color: var(--token-color-foreground-warning-on-surface);
}

.description {
	grid-area: description;
}

.cta {
	grid-area: cta;
}

.closeButton {
	grid-area: close;
}
